export default function ProductDesText({ text, list, className }) {
  return (
    <p
      style={{ whiteSpace: "pre-line" }}
      className={`product-description ${className}`}
    >
      {text}{" "}
      {list?.length ? list?.map((item, index) => (
        <span
          key={index}
          className="banner-products uppercase first-of-type:ml-2 inline-block rounded-[150px] border border-[#483729] bg-white px-2 py-[1px] mr-2 mt-[5px]"
        >
          {item}
        </span>
      )) : null}
    </p>
  )
}
