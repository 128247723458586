import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function CSRNavigator({ onClick }) {
  const csrItems = [
    { label: "Cancer Camp", id: "cancerCamp" },
    { label: "CAR T-cell Therapy", id: "carTCellTherapy" },
    { label: `Women's day`, id: "womensDay" },
  ]

  return (
    <>
      {csrItems?.map((item, index) => (
        <div
          key={index}
          className={`flex justify-center relative  items-center  px-[12px] py-[8px]
       ${index !== csrItems.length - 1 ? "border-end" : ""} cursor-pointer`}
          onClick={() => onClick(item.id)}
        >
          <p className="product-label-text product-label-text-black">
            {item?.label}{" "}
            {item?.colorLabel && (
              <span className={"product-label-text" + " " + item.color}>
                {item?.colorLabel}
              </span>
            )}
          </p>
        </div>
      ))}
    </>
  )
}
