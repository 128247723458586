import React, { useState } from "react"
import CSRIntro from "./Component/CSRIntro"
import OurActivities from "./Component/OurActivities"
import Banner from "../../assets/images/CSR/CSRBanner.png"
import OurApproach from "./Component/OurApproach"
import CSRFunds from "./Component/CSRFunds"
import Mentoring from "./Component/Mentoring"
import Footer from "../../components/Footer"
import Navbar from "../Home/Navbar"
import TCellTherapy from "./Component/TCellTherapy"
import CancerCamp from "./Component/CancerCamp"
import WomenDay from "../../assets/csr/WomensDay1.pdf"
import WomenDay2 from "../../assets/csr/WomensDay2.pdf"
import PdfViewer from "../../components/PDFViewer/PDFViewer"
import HelmetSEO from "../../components/HelmetSEO"

const seoTitle = "Corporate Social Responsibility in welding industry"
const seoDescription = `Advance Metal Powders, actively contribute to the Social, Economic and Environmental
Development of the community through sustainable measures, in which we operate ensuring
participation from the community and thereby create value for the nation.`
const CSRScreen = () => {
  const onClickLink = (id) => {
    if (id === "womensDay") {
      setShowWomenDayPDF(true)
    }
    const isElementFound = document.getElementById(`${id}`)
    if (isElementFound) {
      isElementFound.scrollIntoView({ behavior: "smooth" })
    }
  }
  const [showWomensDayPDF, setShowWomenDayPDF] = useState(false)
  return (
    <div>
      <HelmetSEO title={seoTitle} description={seoDescription} />
      <Navbar csrNavigation onClick={(id) => onClickLink(id)} />
      <div className="pt-[140px] md:pt-[180px]" />
      <CSRIntro />
      <OurActivities />
      <div>
        <img src={Banner} alt="banner" className="w-[100vw]" />
      </div>
       <OurApproach />
     <CSRFunds />
      <Mentoring />
      <CancerCamp />
      <TCellTherapy />
      <Footer />
      {showWomensDayPDF && (
        <PdfViewer
          pdfFile={[WomenDay, WomenDay2]}
          isMultiFiles={true}
          onClose={() => setShowWomenDayPDF(false)}
        />
      )}
    </div>
  )
}

export default CSRScreen
