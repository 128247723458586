import React from "react"
import Button from "../../../components/Button"
import CancerCampPDF from "../../../assets/csr/CancerCamp.pdf"
import PdfViewer from "../../../components/PDFViewer/PDFViewer"
import { useState } from "react"
const CancerCamp = () => {
  const title = `Cancer Camp`
  const description = `The generous donation received by Yash Public Charitable Trust from Advance Metal Powder Pvt. Ltd. is being utilized for our CSR projects, including cardiac and oncology camps in rural areas of Gujarat and Madhya Pradesh, as well as other upliftment projects. We are grateful to receive Rs 10 lakhs in donation, of which Rs 5 lakhs will be allocated to our Mahuvad Hospital project.`
  const description2 = "This would include"

  const list = [
    "YPCT is constructing a hospital in the remote outskirts of Vadodara, at Mahuvad, Taluka Padra, to provide essential treatment and services to the underprivileged sections of society.",
    `The contribution from Advance Metal Powder Pvt. Ltd. will support the procurement of instruments and machinery for the ICU, enhancing our ability to provide quality treatment to patients.`,
    `We have conducted cardiac camps in rural peripheries in Gujarat & Madhya pradesh with the support of Advance Metal Powder Pvt. Ltd. Rs 5 lakhs have been utilized from this fund for the enlisted CSR camps.`,
  ]

  const [showPDF, setShowPDF] = useState(false)
  return (
      <div className="grid grid-cols-6 md:gap-x-[88px] pt-[90px] md:pt-[296px] px-[32px] md:px-[80px] xl:px-[150px]" id="cancerCamp">
        {/* column 1 */}

        <div className="col-span-6 xl:col-span-3 flex flex-col">
          <h2 className="Banner-title">{title}</h2>

          {/* description*/}
          <p className="approachDescription mt-[36px]">{description}</p>
          {/* description*/}
          <p className="approachDescription mt-[36px]">{description2}</p>
          <Button title="View Pdf" className="mt-[28px]" onClick={()=>setShowPDF(true)}/>
        </div>
        <div className="col-span-6 xl:col-span-3 flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 gap-x-4 ">
            {list.map((activity, index) => (
              <div key={index} className="col-span-1">
                <p className="activityTitle">
                  {(index + 1).toString().padStart(2, "0")}.
                </p>
                <p className="activityDescription">{activity}</p>
              </div>
            ))}
          </div>
        </div>
        {showPDF && (
        <PdfViewer
          pdfFile={CancerCampPDF}
          isMultiFiles={false}
          onClose={() => setShowPDF(false)}
        />
      )}
      </div>
  )
}

export default CancerCamp
