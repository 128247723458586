import { toast } from "react-toastify"

const emailjs = require("@emailjs/browser")
let gmail_service_id = "service_i65uk3k"
const publicKey = "ynG1e9xxqgt84Yjja"
const enquiryTemplateId = "template_mzckxa4"

export const sendEmail = async ({ templateId, params, attachments }) => {
  // const userData = {
  //   name:"Parag",
  //   from:"aditya.sharma@brovitech.com",
  //   to: "aditya.sharma@brovitech.com",
  //   message: "xyz",
  // }
  // try {
  //   let response = await fetch("https://www.advancemetalpowder.com/api/sendEmail.php", { 
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(userData),
  // })
  // console.log("first",data);
  //   const data = await response.json();
  //   console.log(data);
  // } catch (error) {
  //   console.log("error", error)
  // }
  const serviceId = gmail_service_id

  const selectedTemplateID = templateId || enquiryTemplateId
  const templateParams = {
    to_name: "Parag",
    from_name: params.name,
    ...params,
  }

  // await initialiseEmailJS(
  emailjs.init({ publicKey: "ynG1e9xxqgt84Yjja" })
  let emailAttachments = []
  if (attachments && !!attachments.length) {
    emailAttachments = attachments.map((attachment) => {
      return { name: attachment.name, data: attachment.data }
    })
  }
  console.log("emailAttachments", emailAttachments)

  emailjs
    .send(
      serviceId,
      selectedTemplateID,
      templateParams,
      emailAttachments,
      publicKey
    )
    .then((response) => {
      console.log("Email sent successfully!", response)
      toast.success("Request Submitted successfully!");
       return response
    })
    .catch((error) => {
      console.error("Error sending email:", error)
      toast.error("Something went wrong, please try again later");
        return error
    })
}