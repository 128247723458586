import React, { useState } from "react"
import DownloadIcon from "../../../assets/brochure/DownloadIcon"
import DownloadPdfForm from "./DownloadPdfForm"
import PDF1 from "../../../assets/Downloads/CuNi (70.30).pdf"
import PDF2 from "../../../assets/Downloads/CuNi (90.10).pdf"
import PDF3 from "../../../assets/Downloads/ERNI1.pdf"
import PDF4 from "../../../assets/Downloads/ERNICR3.pdf"
import PDF5 from "../../../assets/Downloads/ERNICRCOMO1.pdf"
import PDF6 from "../../../assets/Downloads/ERNICRFE2.pdf"
import PD7 from "../../../assets/Downloads/ERNICRMO3.pdf"
import PDF8 from "../../../assets/Downloads/ERNICRMO4.pdf"
import PDF9 from "../../../assets/Downloads/ERNICRMO7.pdf"
import PDF10 from "../../../assets/Downloads/ERNICRMO10.pdf"
import PDF11 from "../../../assets/Downloads/ERNICRMO13.pdf"
import PDF12 from "../../../assets/Downloads/ERNICRMO14.pdf"
import PDF13 from "../../../assets/Downloads/ERNICU7.pdf"
import PDF14 from "../../../assets/Downloads/Fe.Ni (36%).pdf"
import PDF15 from "../../../assets/Downloads/Fe.Ni (55%).pdf"
import PDF16 from "../../../assets/Downloads/Fe.Ni (60%).pdf"


import thumbnail from "../../../assets/Downloads/thumbnail.png"
import { downloadPDF } from "../../../Utility/download"
const ProductTechnicalSection = () => {
  const [selectedPDF, setSelectedPDF] = useState(null)

  const pdfs = [
    {
      name: "CuNi (70.30)",
      path: PDF1,
      thumbnail,
      size: "110 kb"
    },
    {
      name: "CuNi (90.10)",
      path: PDF2,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "ERNI1",
      path: PDF3,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "ERNICR3",
      path: PDF4,
      thumbnail,
      size: "108 kb"
    },
    {
      name: "ERNICRCOMO1",
      path: PDF5,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "ERNICRFE2",
      path: PDF6,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "ERNICRMO3",
      path: PD7,
      thumbnail,
      size: "108 kb"
    },
    {
      name: "ERNICRMO4",
      path: PDF8,
      thumbnail,
      size: "108 kb"
    },
    {
      name: "ERNICRMO7",
      path: PDF9,
      thumbnail,
      size: "109 kb"
    },
    {
      name: "ERNICRMO10",
      path: PDF10,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "ERNICRMO13",
      path: PDF11,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "ERNICRMO14",
      path: PDF12,
      thumbnail,
      size: "118 kb"
    },
    {
      name: "ERNICU7",
      path: PDF13,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "Fe.Ni (36%)",
      path: PDF14,
      thumbnail,
      size: "110 kb"
    },
    {
      name: "Fe.Ni (55%)",
      path: PDF15,
      thumbnail,
      size: "107 kb"
    },
    {
      name: "Fe.Ni (60%)",
      path: PDF16,
      thumbnail,
      size: "107 kb"
    },
  ]

  // Use the imported PDFs
  const handlePDFClick = (item) => {
    setSelectedPDF(item)
  }
  return (
    <div className="pt-[100px] px-[32px] md:ps-[101px] relative">
       <p className="font-Fira-Sans font-[300] text-[24px] leading-[25.2px] tracking-[2%] text-[#003088]">Product Technical Datasheet</p>
      <div className="grid grid-cols-2 md:grid-cols-4 mt-[34px] gap-x-[48px] gap-y-[68px] max-w-[900px] flex-wrap justify-between">
        {pdfs?.map((item) => (
          <div
            className="col-span-1 cursor-pointer"
            onClick={() => handlePDFClick(item)}
            key={item?.name}
          >
            <div className="flex items-end">
            <img
              src={item.thumbnail}
              alt="PDF Thumbnail"
              className="obejct-contain  border-[0px] border-solid border-[#54565A] mr-[8px]"
            />
             <DownloadIcon />
            </div>
            

            <div className="flex  mt-[8px]">
              <div>
                <div className="font-Fira-Sans font-[400] text-[20px] leading-[30px]">
                  {item.name}
                </div>
                <div className="font-Fira-Sans font-[400] text-[14px] leading-[21px] text-[#02111B]">
                {`(Size approx. ${item?.size})`}
                </div>
              </div>
             
            </div>
          </div>
        ))}
      </div>
      <DownloadPdfForm
        isOpen={!!selectedPDF}
        onClose={() => setSelectedPDF(null)}
        onSubmitSuccess={() => {
          downloadPDF(selectedPDF.path, `${selectedPDF.name}.pdf`)
        }}
      />
    </div>
  )
}

export default ProductTechnicalSection
