export const RoutesLink = {
  Home: '/',
  Home1: '/home',
  About: '/about-us',
  Contact: '/contact',
  product1: "/advanceCRAFT",
  product2: "/Hoganas-iron-powder",
  product3: "/6K-Additive-Metal",
  advanceForge: "/advanceForge",
  advancePro: "/advancePro",
  certification: "/certification",
  career: "/careers",
  enquire: "/enquire",
  download: "/download",
  csr: "/csr",
}