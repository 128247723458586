import React from "react"

const DownloadIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19993 25.0001H17.7998C21.1939 25.0001 22.8909 25.0001 23.9453 23.9457C24.9998 22.8913 24.9998 21.1942 24.9998 17.8002V16.6002C24.9998 13.2061 24.9998 11.5091 23.9453 10.4547C23.0233 9.53261 21.6098 9.41688 18.9998 9.40234M6.99994 9.40234C4.38993 9.41688 2.97645 9.53261 2.05441 10.4547C1 11.5091 1 13.2061 1 16.6002V17.8002C1 21.1942 1 22.8913 2.05441 23.9457C2.41416 24.3054 2.84873 24.5424 3.39998 24.6986"
        stroke="#02111B"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M12.9999 1V16.5998M12.9999 16.5998L9.3999 12.3999M12.9999 16.5998L16.5998 12.3999"
        stroke="#02111B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
