import Footer from "../../components/Footer";
import Navbar from "../Home/Navbar";
import radialGroup from "../../assets/images/Banner/RadialGroup.svg";
import EnquiryForm from "../../components/Forms/EnquiryForm";
import EnquiryProductMainContainer from "../../components/Containers/EnquiryProductMainComponent";
import HelmetSEO from "../../components/HelmetSEO";

const seoTitle = "Advance Metal Powder: Best Welding Consumable Manufacturer in India."
const seoDescription = `We’re here to assist with any questions or inquiries about our welding consumables. Whether you
need product details, technical support, or pricing information, simply fill out the form below, and
our team will get back to you promptly. Let us help you find the right solutions for your welding
requirements.`
export default function Enquiry() {

  const enquiryData = {
    title: "Enquire",
    description: "We’re here to assist with any questions or inquiries about our welding consumables. Whether you need product details, technical support, or pricing information, simply fill out the form below, and our team will get back to you promptly. Let us help you find the right solutions for your welding requirements.",
    image: radialGroup
  }

  return (<>
  <HelmetSEO title={seoTitle} description={seoDescription} />
    <Navbar />
    <EnquiryProductMainContainer {...enquiryData}>
      <EnquiryForm />
    </EnquiryProductMainContainer>
    <Footer />
  </>)
}